import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "helpers/tixxt-router";
import { includes, pickBy } from "lodash";
import { selectNetworkId } from "selectors/environment";
import MembershipForm from "./MembershipForm";
import { fetchApi } from "../../helpers/reactQueryApi";

const changeRequestsProfileFields =
  window.Preload.current_network.config.memberships?.change_requests
    ?.profile_fields;

export default function NewChangeRequest() {
  const { membershipId } = useParams();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const externalItemId = membershipId;
  const filterProperties = useCallback(
    ({ name }) => includes(changeRequestsProfileFields, name),
    [],
  );

  const onSubmit = async (values) => {
    const body = pickBy(values, (value, name) => filterProperties({ name }));
    return fetchApi(`/members/${membershipId}/change_requests`, {
      method: "POST",
      body,
    });
  };

  const onSubmitSuccess = () => {
    location.replace(`/members/${membershipId}`);
    toastr.success(I18n.t("js.saving_successful"));
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      title={I18n.t("js.memberships.change_requests.title")}
      filterProperties={filterProperties}
    />
  );
}
