import React from "react";
import { compact } from "lodash";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  FavoriteGroupIcon,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import GroupCategorySections from "../shared/GroupCategorySections";
import useOpenJoinRequestsCount from "components/shared/hooks/useOpenJoinRequests";

export default function TasksUserbar() {
  const openJoinRequestCount = useOpenJoinRequestsCount();

  const getUserbarLinkTitle = (groupSlug) => {
    return compact([
      openJoinRequestCount[groupSlug]
        ? `${I18n.t("js.administration.join_requests.label")}: ${
            openJoinRequestCount[groupSlug]
          }`
        : null,
    ]).join(" , ");
  };
  return (
    <>
      <TixxtModuleSwitcher activeModule={"tasks"} />
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          <UserbarLink to="/tasks/my">
            {I18n.t("js.tasks.my_tasks")}
          </UserbarLink>
          <UserbarLink to="/tasks/assigned">
            {"  Anderen zugewiesen"}
          </UserbarLink>
          <UserbarLink to="/tasks/declined">{"  Abgelehnt"}</UserbarLink>
          <UserbarLink to="/tasks/starred">{"  Markiert"}</UserbarLink>
          <UserbarLink to="/tasks/archive">{"  Archiv"}</UserbarLink>
          <UserbarLink to="/tasks/notes">{"  Notizen"}</UserbarLink>
        </UserbarSection>
        <GroupCategorySections unreadCountByGroupSlug={openJoinRequestCount}>
          {({ slug, name, favorited }, { collapsed }) => (
            <UserbarLink
              className="relative"
              key={slug}
              to={`/groups/${slug}/tasks`}
              showWhileClosed={favorited}
              title={getUserbarLinkTitle(slug)}
              unreads={openJoinRequestCount[slug] || 0}
            >
              {name}
              <FavoriteGroupIcon favorited={favorited} collapsed={collapsed} />
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
