import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getChatProfile,
  getComposingHomie,
} from "../../../selectors/chat/chat";

import ChatHeader from "../shared/ChatHeader";
import { BackToRootButton } from "../shared/BackButton";
import QuickChatSelect from "./QuickChatSelect";
import ChatRoomSettingsButton from "./ChatRoomSettingsButton";
import Chat from "./Chat";
import MessageComposer from "./MessageComposer";

const ChatRoom = ({ chatId, name, composingHomie }) => (
  <>
    <ChatHeader
      title={name}
      subtitle={
        composingHomie
          ? chatId.indexOf("member/") === 0
            ? I18n.t("js.chat.room.somebody_is_typing")
            : I18n.t("js.chat.room.member_is_typing", {
                name: composingHomie.name,
              })
          : null
      }
      chatId={chatId}
      ButtonLeft={<BackToRootButton chatId={chatId} />}
      ButtonRight={<ChatRoomSettingsButton chatId={chatId} />}
    />
    <Chat id={chatId} />
    <MessageComposer chatId={chatId} />
    <QuickChatSelect chatId={chatId} />
  </>
);
ChatRoom.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  composingHomie: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default connect((state, { chatId }) => ({
  ...getChatProfile(state, { chatId }),
  composingHomie: getComposingHomie(state, { chatId }),
}))(ChatRoom);
