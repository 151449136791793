import { fetchApi } from "helpers/reactQueryApi";
import store from "../store";

export default class Bridge {
  static postMessage(message) {
    const isIOS = /iOS/.test(navigator.userAgent);

    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      window.webkit?.messageHandlers.nativeApp.postMessage(message);
    }

    if (isAndroid) {
      window.nativeApp?.postMessage(JSON.stringify(message));
    }
  }

  static toggleUserbar() {
    const userbar = document.getElementById("userbar-root");

    if (userbar.classList.contains("userbar--visible")) {
      userbar.classList.remove("userbar--visible");
    } else {
      userbar.classList.add("userbar--visible");
    }
  }

  static toggleChat() {
    const chat = document.getElementById("chatbar-root");

    if (chat.classList.contains("chatbar--visible")) {
      chat.classList.remove("chatbar--visible");
    } else {
      chat.classList.add("chatbar--visible");
    }
  }

  static toggleSearchbar() {
    const searchbar = document.getElementById("app__header");
    const searchInput = document.getElementsByClassName("search-bar__input")[0];

    if (searchbar.classList.contains("header--visible")) {
      searchbar.classList.remove("header--visible");
      searchInput.blur();
    } else {
      searchbar.classList.add("header--visible");
      searchInput.focus();
    }
  }

  static registerDevice(data) {
    fetchApi("/api/v1/devices", {
      method: "POST",
      body: data,
    });
  }

  static async removeDevice(deviceUniqId) {
    const result = await fetchApi(`/api/v1/devices/${deviceUniqId}`, {
      method: "DELETE",
    });

    Bridge.postMessage({ deletedDevice: result });
  }

  static showChat(id) {
    store.dispatch({
      type: "chat/NAVIGATE",
      payload: { route: "showChat", params: { chatId: id } },
    });
  }

  static async loadNetworkConfig() {
    const network = await fetchApi("/api/v1/network");

    const pages = await fetchApi("/pages");

    Bridge.postMessage({
      setNetworkConfig: network,
      setPages: pages,
    });
  }

  static async loadUnreads() {
    const unreads = await fetchApi("/api/v1/unreads");

    Bridge.postMessage({ unreads });
  }

  static getDeviceUniqueId(setDeviceUniqueId) {
    Bridge.postMessage({
      getDeviceUniqueId: "setDeviceUniqueId",
    });

    Bridge.setDeviceUniqueId = setDeviceUniqueId;
  }

  static setPageTitle(title) {
    Bridge.postMessage({
      pageTitle: title,
    });
  }

  static goBack() {
    Bridge.postMessage({
      goBack: "goBack",
    });
  }

  static setMemberIsLoggedIn(isLoggedIn) {
    Bridge.postMessage({
      isLoggedIn,
    });
  }

  static setMemberId(membershipId) {
    Bridge.postMessage({
      membershipId,
    });
  }

  static setMemberCanChat(canChat) {
    Bridge.postMessage({
      canChat,
    });
  }

  static configureLayout() {
    document.body.classList.add("tixxt--in-app");

    let pageTitle = document.getElementById("static-page-title");

    if (pageTitle) {
      Bridge.setPageTitle(pageTitle.textContent);
    }

    let title = document.querySelector("title");
    title.parentNode.removeChild(title);
  }

  static async getAuthToken(key, options) {
    const token = await fetchApi("/api/v1/get-web-token");

    Bridge.postMessage({
      [key]: { authToken: token, options },
    });
  }

  static alert(title, message, defaultValue, callbackName, callback) {
    Bridge.postMessage({
      alert: { title, message, defaultValue, callbackName },
    });

    if (callback) {
      Bridge[callbackName] = callback;
    }
  }

  static prompt(message, defaultValue, callback) {
    Bridge.alert("", message, defaultValue, "promptCallback", callback);
  }

  static confirm(message, callback) {
    Bridge.alert(message, "", undefined, "confirmCallback", callback);
  }
}
