import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSelector } from "react-redux";
import { networkSelector } from "selectors/environment";
import SearchBar from "components/layout/SearchBar";
import UserCentral from "components/layout/UserCentral";
import { useFeaturesUnreads } from "api/unreads";
import UnreadBadge from "components/shared/UnreadBadge";
import { map, omit, sum, values } from "lodash";
import { State } from "../../@types";
import useLogoScrollResize from "./useLogoScrollResize";
import CustomerCentralUpdateCheck from "components/layout/CustomerCentralUpdateCheck";
import TeamsRenewOAuthCredentialsCheck from "components/layout/TeamsRenewOAuthCredentialsCheck";

export default function TixxtHeader() {
  const network = useSelector(networkSelector);
  const theme = useSelector((state: State) => state.theme);
  const groupCategoryTheme = useSelector(
    (state: State) => state.groupCategoryTheme,
  );
  const groupTheme = useSelector((state: State) => state.groupTheme);
  let logoUrl = theme.override
    ? `/api/storage/assets/${theme.logoImage?.id}/preview`
    : theme.logoUrl;
  if (groupCategoryTheme?.logoUrl) logoUrl = groupCategoryTheme.logoUrl;
  if (groupTheme?.logoUrl) logoUrl = groupTheme.logoUrl;

  const duo = theme?.userbarIconType == "duotone";
  const { data: unreads } = useFeaturesUnreads() || 0;

  const unreadCount =
    sum(
      values(omit(unreads, ["Invites", "Notifications", "GroupJoinRequests"])),
    ) + sum(map(unreads?.GroupJoinRequests, "open_join_requests"));

  const logoRef = useLogoScrollResize();

  return (
    <>
      <div className={"header__content"}>
        <button className="header__burger relative" data-togglebar={"userbar"}>
          <FontAwesomeIcon
            icon={duo ? duotone("bars") : light("bars")}
            className={"fa-xl"}
          />
          <UnreadBadge
            count={unreadCount}
            className={"absolute top-1 right-1"}
          />
        </button>
        <div className={"header__logo"} ref={logoRef}>
          <a
            className="header__logo-link"
            href={"/"}
            data-turbo={"false"}
            data-override={theme.override}
          >
            {logoUrl ? (
              <img
                className="header__logo-img"
                src={logoUrl}
                alt={network.name}
              />
            ) : (
              network.name
            )}
          </a>
        </div>
        <div className={"header__home-icon"}>
          <a className="header__link relative" href={"/"} data-turbo={"false"}>
            <FontAwesomeIcon
              title="Start"
              className="home-icon fa-xl"
              icon={duo ? duotone("house") : light("house")}
            />
          </a>
        </div>
        <div className={"header__search"}>
          <SearchBar duo={duo} />
        </div>
        <div className={"header__user-central"}>
          <CustomerCentralUpdateCheck>
            {({ availableUpdates }) => (
              <UserCentral duo={duo} availableUpdates={availableUpdates} />
            )}
          </CustomerCentralUpdateCheck>
        </div>
        <TeamsRenewOAuthCredentialsCheck />
      </div>
      <div className="header__banner" />
    </>
  );
}
