// Add pushState and popState events to window so we can listen to them in useLocation
// inspiration: https://stackoverflow.com/questions/5129386/how-to-detect-when-history-pushstate-and-history-replacestate-are-used
function addEventDispatch(orig, type) {
  return function (...args) {
    const result = orig.apply(history, args);
    const event = new Event(type);
    event["arguments"] = args;
    window.dispatchEvent(event);
    return result;
  };
}

history.pushState = addEventDispatch(history.pushState, "tixxt:pushstate");
history.replaceState = addEventDispatch(
  history.replaceState,
  "tixxt:replacestate",
);
