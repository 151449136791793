import React from "react";

import { compact } from "lodash";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  FavoriteGroupIcon,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import GroupCategorySections from "../shared/GroupCategorySections";
import { useFeaturesUnreads } from "../../api/unreads";
import UnreadBadge from "components/shared/UnreadBadge";
import useOpenJoinRequestsCount from "components/shared/hooks/useOpenJoinRequests";

export default function AppointmentsUserbar() {
  const { data: unreads } = useFeaturesUnreads();

  const unreadCount = unreads ? unreads?.Calendars : 0;
  const openJoinRequestCount = useOpenJoinRequestsCount();

  const getUserbarLinkTitle = (groupSlug) => {
    return compact([
      openJoinRequestCount[groupSlug]
        ? `${I18n.t("js.administration.join_requests.label")}: ${
            openJoinRequestCount[groupSlug]
          }`
        : null,
    ]).join(" , ");
  };

  return (
    <>
      <TixxtModuleSwitcher activeModule={"appointments"} />
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          <UserbarLink to="/appointments">
            {I18n.t("js.calendars.all_appointments")}
          </UserbarLink>
          <UserbarLink to="/appointments/participating">
            {I18n.t("js.calendars.participating_appointments")}
          </UserbarLink>
          <UserbarLink to="/appointments/invites">
            <div>
              {I18n.t("js.calendars.invites.title")}
              <UnreadBadge count={unreadCount} className="ml-1" />
            </div>
          </UserbarLink>
        </UserbarSection>
        <GroupCategorySections unreadCountByGroupSlug={openJoinRequestCount}>
          {({ slug, name, favorited }, { collapsed }) => (
            <UserbarLink
              key={slug}
              className="relative"
              to={`/groups/${slug}/calendars`}
              showWhileClosed={favorited}
              title={getUserbarLinkTitle(slug)}
              unreads={openJoinRequestCount[slug] || 0}
            >
              {name}
              <FavoriteGroupIcon favorited={favorited} collapsed={collapsed} />
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
