import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers, withProps, lifecycle } from "recompose";
import { isEmpty, trim } from "lodash";
import Textarea from "react-textarea-autosize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  getComposerText,
  getComposerMessageToQuoteId,
} from "../../../../selectors/chat/composer";
import MessageToQuote from "./MessageToQuote";
import classNames from "classnames";

const MessageComposer = ({
  submit,
  text,
  onSetText,
  onKeyDown,
  messageToQuoteId,
  chatId,
  inputRef,
}) => (
  <div
    className={classNames(
      "messageComposer flex flex-col border-t border-neutral",
      {
        "fixed left-0 right-0 bottom-16": window.isApp,
      },
    )}
  >
    <MessageToQuote id={messageToQuoteId} chatId={chatId} />
    <form onSubmit={submit} className={"flex items-stretch"}>
      <Textarea
        ref={inputRef}
        minRows={1}
        maxRows={8}
        placeholder={I18n.t("js.chat.composer.placeholder")}
        onKeyDown={onKeyDown}
        onChange={onSetText}
        value={text}
        autoFocus
        className={
          "chat__message-composer grow text-sm rounded-none border-0 border-r border-neutral"
        }
      />
      <button
        className="btn btn-primary btn-sm rounded-none messageComposerAction"
        type="submit"
        disabled={!trim(text)}
      >
        <FontAwesomeIcon icon={solid("paper-plane")} />
      </button>
    </form>
  </div>
);
MessageComposer.propTypes = {
  text: PropTypes.string,
  onSetText: PropTypes.func,
  onKeyDown: PropTypes.func,
  submit: PropTypes.func,
  messageToQuoteId: PropTypes.string,
};

export default compose(
  withProps({ inputRef: React.createRef() }),
  connect(
    (state, { chatId }) => ({
      text: getComposerText(state, { chatId }),
      messageToQuoteId: getComposerMessageToQuoteId(state, { chatId }),
    }),
    (dispatch, { chatId }) => ({
      sendMessage: (payload) =>
        dispatch({
          type: "chat/SEND_MESSAGE",
          meta: { chatId },
          payload,
        }),
      setText: (text) =>
        dispatch({ type: "chat/COMPOSER/SET_TEXT", text, chatId }),
    }),
  ),
  withHandlers({
    submit:
      ({ text, sendMessage, messageToQuoteId }) =>
      (e) => {
        e.preventDefault();
        if (isEmpty(trim(text))) {
          return;
        }

        sendMessage({ text, quoted_message_id: messageToQuoteId });
      },
  }),
  withHandlers({
    onSetText:
      ({ setText }) =>
      (e) =>
        setText(e.target.value),
    onKeyDown:
      ({ submit }) =>
      (e) => {
        if (e.keyCode == "13") {
          if (!e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
            submit(e);
          }
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      this.props.inputRef.current.focus();
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.chatId !== prevProps.chatId ||
        (this.props.messageToQuoteId &&
          prevProps.messageToQuoteId !== this.props.messageToQuoteId)
      ) {
        this.props.inputRef.current.focus();
      }
    },
  }),
)(MessageComposer);
