import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { flatMap } from "lodash";
import moment from "moment";

import { getRequestStateForChat } from "../../../selectors/chat/chat";
import { getMessagesForChat } from "../../../selectors/chat/messages";

import ScrollContainer from "../shared/ScrollContainer";
import ChatMessage from "./ChatMessage";
import DaySeparator from "./DaySeparator";
import classNames from "classnames";

const Chat = ({
  id,
  messages,
  onStartReached,
  onEndReached,
  loadingMessages,
  moreMessagesAvailable,
}) => {
  let lastMessageFrom = null;
  let lastMessageDate = null;

  return (
    <ScrollContainer
      id={id}
      className={classNames("grow messagesContainer flex flex-col gap-3 py-3", {
        "pt-16 pb-16": window.isApp,
      })}
      onStartReached={onStartReached}
      onEndReached={onEndReached}
      callbackThreshold={2}
      autoscroll
      autoscrollThreashold={10}
      startElement={
        moreMessagesAvailable ? null : (
          <div className="chatNoMoreMessages text-center text-muted text-sm mt-2">
            {I18n.t("js.chat.room.no_more_messages")}
          </div>
        )
      }
    >
      {loadingMessages ? (
        <div className="chatLoadMessagesIndicator text-center py-2">
          <i className="fa fa-spinner fa-spin text-muted" />
        </div>
      ) : null}
      {flatMap(messages, (message, i) => {
        const result = [];
        const sameAuthor = lastMessageFrom === message.from.id;
        const sameDay = moment(message.created_at).isSame(
          lastMessageDate,
          "day",
        );

        if (!sameDay) {
          result.push(
            <DaySeparator
              key={message.created_at || `sep+${i}`}
              date={message.created_at}
            />,
          );
        }

        result.push(
          <ChatMessage
            key={message.id || `new+${i}`}
            groupedMessage={sameAuthor && sameDay}
            {...message}
          />,
        );

        lastMessageFrom = message.from.id;
        lastMessageDate = moment(message.created_at);

        return result;
      })}
    </ScrollContainer>
  );
};
Chat.propTypes = {
  id: PropTypes.string,
  messages: PropTypes.array,
  onStartReached: PropTypes.func,
  onEndReached: PropTypes.func,
  loadingMessages: PropTypes.bool,
  moreMessagesAvailable: PropTypes.bool,
};

export default connect(
  (state, { id }) => ({
    ...getRequestStateForChat(state, { chatId: id }),
    messages: getMessagesForChat(state, { chatId: id }),
  }),
  (dispatch, { id }) => ({
    onStartReached: () =>
      dispatch({
        type: "chat/CHAT/SCROLL_START_REACHED",
        chatId: id,
      }),
    onEndReached: () =>
      dispatch({
        type: "chat/CHAT/SCROLL_END_REACHED",
        chatId: id,
      }),
  }),
)(Chat);
