import React from "react";
import { map, slice, findIndex, trim, compact, times } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import classnames from "classnames";
// import Hotkeys from "hotkeys-js";

import { getCurrentChat } from "../../../selectors/chat/application";
import {
  getSortedChats,
  getComposingHomie,
  getCounterForChat,
} from "../../../selectors/chat/chat";
import ChatImage from "../shared/ChatImage";
import Notification from "../shared/Notification";
import Composing from "../shared/Composing";
import classNames from "classnames";

const chatInitials = (chatName) => {
  if (chatName.length < 2) {
    return chatName.toUpperCase();
  }

  const parts = compact(chatName.split(/[-_/\\.@\s]/));
  const initials =
    parts.length >= 2
      ? trim(parts[0])[0] + trim(parts[1])[0]
      : chatName[0] + chatName[1];

  return initials.toUpperCase();
};

const Chat = connect(
  (state, { id }) => ({
    counter: getCounterForChat(state, { chatId: id }),
    composingHomie: getComposingHomie(state, { chatId: id }),
  }),
  (dispatch, { id }) => ({
    select: () =>
      dispatch({
        type: "chat/NAVIGATE",
        payload: { route: "showChat", params: { chatId: id } },
      }),
  }),
)(({ id, name, selected, select, counter, state, composingHomie }) => (
  <button
    className={classnames(
      "chat__quick_select_item relative flex flex-col items-center px-2 py-1 bg-secondary/25",
      {
        "chat__quick_select_item--active": selected,
      },
    )}
    onClick={select}
    title={name}
  >
    <div className={"relative"}>
      <ChatImage chatId={id} />
      <Notification
        counter={state?.last_read_id ? counter : -1}
        className={"absolute top-0 right-0"}
      />
      {composingHomie ? <Composing /> : null}
    </div>
    <span className="chatName font-semibold text-xs">{chatInitials(name)}</span>
  </button>
));

const QuickChatSelect = ({ chats, chatId }) => (
  <div
    className={classNames("chat__quick_select border-t border-neutral", {
      "fixed left-0 right-0 bottom-0": window.isApp,
    })}
  >
    {map(
      chats,
      (chat) =>
        chat.id && (
          <Chat key={chat.id} selected={chat.id === chatId} {...chat} />
        ),
    )}
    {times(10 - chats.length, (i) => (
      <div key={i} className={"placeholder"} />
    ))}
  </div>
);

export default compose(
  connect(
    (state) => ({
      currentChat: getCurrentChat(state),
      chats: slice(getSortedChats(state), 0, 10),
    }),
    (dispatch) => ({
      selectChat: (chatId) =>
        dispatch({
          type: "chat/NAVIGATE",
          payload: { route: "showChat", params: { chatId } },
        }),
    }),
  ),
  withHandlers({
    nextChat:
      ({ chats, selectChat, currentChat }) =>
      () => {
        const currIndex = findIndex(chats, (c) => c.id === currentChat);
        const nextIndex =
          currIndex < 0 || currIndex + 1 >= chats.length ? 0 : currIndex + 1;
        selectChat(chats[nextIndex].id);
      },
    prevChat:
      ({ chats, selectChat, currentChat }) =>
      () => {
        const currIndex = findIndex(chats, (c) => c.id === currentChat);
        const prevIndex =
          currIndex < 0 || currIndex - 1 < 0 ? chats.length - 1 : currIndex - 1;
        selectChat(chats[prevIndex].id);
      },
  }),
  // lifecycle({
  //   componentDidMount() {
  //     Hotkeys("ctrl+command+right", this.props.nextChat);
  //     Hotkeys("ctrl+command+left", this.props.prevChat);
  //   },
  //   componentWillUnmount() {
  //     Hotkeys.unbind("ctrl+command+right");
  //     Hotkeys.unbind("ctrl+command+left");
  //   },
  // }),
)(QuickChatSelect);
