import React from "react";
import { groupBy, map, mapValues, partition, sum, find, compact } from "lodash";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "helpers/tixxt-router";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  FavoriteGroupIcon,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import GroupCategorySections from "../shared/GroupCategorySections";
import { useActivitiesUnreads } from "api/unreads";
import indexBy from "helpers/indexBy";
import { State } from "../../@types";
import InfoUserbar from "components/info/Userbar";
import useOpenJoinRequestsCount from "components/shared/hooks/useOpenJoinRequests";

export default function ActivitystreamsUserbar() {
  let networkStreams;
  networkStreams = useSelector((state: State) => state.network.activitystreams);

  if (Preload.current_member && Preload.current_member.is_external) {
    networkStreams = [find(networkStreams, { slug: "all" })];
  }
  const { data: unreads } = useActivitiesUnreads();

  const [groupUnreads, networkUnreads] = partition(unreads, "group_slug");
  const networkUnreadsByStreamSlug = indexBy(
    networkUnreads,
    "activitystream_slug",
  );

  const unreadCountByGroupSlug = mapValues(
    groupBy(groupUnreads, "group_slug"),
    (values) => sum(map(values, "unreads")),
  );

  const location = useLocation();

  const openJoinRequestCount = useOpenJoinRequestsCount();

  const getUserbarLinkTitle = (groupSlug) => {
    return compact([
      unreadCountByGroupSlug[groupSlug]
        ? `${I18n.t("js.activitystreams.module_name")}: ${
            unreadCountByGroupSlug[groupSlug]
          }`
        : null,
      openJoinRequestCount[groupSlug]
        ? `${I18n.t("js.administration.join_requests.label")}: ${
            openJoinRequestCount[groupSlug]
          }`
        : null,
    ]).join(" , ");
  };

  const unreadSumBySlug = mapValues(
    unreadCountByGroupSlug,
    (value, key) => value + (openJoinRequestCount[key] || 0),
  );

  if (!Tixxt.currentMember) return <InfoUserbar />;
  return (
    <>
      <TixxtModuleSwitcher activeModule={"activities"} />
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          {map(networkStreams, ({ slug, name }) => (
            <UserbarLink
              key={slug}
              to={`/activitystreams/${slug}`}
              unreads={networkUnreadsByStreamSlug[slug]?.sum}
            >
              {name}
            </UserbarLink>
          ))}
        </UserbarSection>
        <GroupCategorySections unreadCountByGroupSlug={unreadSumBySlug}>
          {({ slug: groupSlug, name, favorited }, { collapsed }) => (
            <UserbarLink
              key={groupSlug}
              className="relative"
              to={`/groups/${groupSlug}/activitystreams/all`}
              active={matchPath(
                `/groups/${groupSlug}/activitystreams/*`,
                location.pathname,
              )}
              showWhileClosed={favorited}
              title={getUserbarLinkTitle(groupSlug)}
              unreads={
                unreadCountByGroupSlug[groupSlug] +
                (openJoinRequestCount[groupSlug] || 0)
              }
            >
              {name}
              <FavoriteGroupIcon favorited={favorited} collapsed={collapsed} />
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
