import React from "react";
import classNames from "classnames";

type Props = {
  count?: number;
  className?: string;
  unstyled?: boolean;
};

export default function UnreadBadge({ count, className }: Props) {
  if (!count || count === 0) return null;

  return (
    <span
      className={classNames(
        "badge rounded-xl self-center bg-unread text-[10px]",
        {
          "px-1": count >= 10,
        },
        className,
      )}
    >
      {count > 99 ? "99+" : count}
    </span>
  );
}
