import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "helpers/tixxt-router";

let originalNavigate;

function shimBackboneNavigate(navigate: NavigateFunction) {
  originalNavigate = Backbone.history.navigate;
  Backbone.history.navigate = function reactRouterNavigate(
    path: string,
    options?: { replace?: boolean },
  ) {
    const currentPath = [
      location.pathname,
      location.search,
      location.hash,
    ].join("");
    if (currentPath !== path) {
      debug(
        "[BackboneHistoryShim] navigate(",
        path,
        { replace: !!options?.replace },
        ")",
      );
      navigate(path, { replace: !!options?.replace });
    }
  };
}

function restoreBackboneNavigate() {
  Backbone.history.navigate = originalNavigate;
}

export default function BackboneHistoryShim() {
  const navigate = useNavigate();
  useEffect(() => {
    shimBackboneNavigate(navigate);
    return restoreBackboneNavigate;
  }, []);

  return null;
}
